<template>
  <moe-page title="品牌管理">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="font-20 fwb">品牌管理</div>
        </div>
      </template>

      <moe-inquire @search="brandSearch">
        <el-form-item label="品牌状态">
          <moe-select type="statusList" v-model="brandParams.status" placeholder="选择品牌状态"></moe-select>
        </el-form-item>
        <el-form-item label="品牌名称">
          <el-input v-model.trim="brandParams.name" placeholder="输入品牌名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="品牌编号">
          <el-input :value="brandParams.id" @input="(value) => brandParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入品牌编号" maxlength="50" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="brandTableRef" url="/shop/brand/list" :params="brandParams" :number-show="false">
        <template slot="tool">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/contentManage/brandManage/add')">新增品牌</el-button>
        </template>
        <el-table-column prop="id" label="品牌编号" width="80" />
        <el-table-column prop="name" label="品牌名称" min-width="150" :show-overflow-tooltip="true" />

        <el-table-column label="品牌图标" min-width="150">
          <template slot-scope="{ row }">
            <moe-image :src="row.icon" width="100px" height="100px" />
          </template>
        </el-table-column>

        <el-table-column prop="introduce" label="品牌介绍" min-width="150" :show-overflow-tooltip="true" />

        <el-table-column label="状态" width="90">
          <template slot-scope="{ row }">
            <div v-if="row.status" @click="updateBarndStatus(row, false, '禁用')">
              <moe-tag type="success"><i class="el-icon-unlock" />启用</moe-tag>
            </div>
            <div v-else @click="updateBarndStatus(row, true, '启用')">
              <moe-tag type="danger"><i class="el-icon-lock" />禁用</moe-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="250" fixed="right">
          <div class="moe-table__btns" slot-scope="{ row }">
            <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/contentManage/brandManage/add`, { id: row.id })">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageBrandManageList',
  data() {
    return {
      brandParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        status: '',
      }
    }
  },
  methods: {
    brandSearch(isSearch) {
      if (!isSearch) {
        this.brandParams = {
          pageNum: 1,
          pageSize: 10,
          status: '',
          name: '',
          id: '',
        }
      }

      this.$refs['brandTableRef'].searchData();
    },
    updateBarndStatus({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要"${text}"当前选项吗？`, () => {
        this.$moe_api.BRAND_API.updateBarndStatus({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['brandTableRef'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 删除品牌 */
    handleDelete({ id, name }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.BRAND_API.deleteBrand({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['brandTableRef'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
  }
}
</script>

<style lang="scss">

</style>